*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
body,
html {
  height: 100%;
  overflow-x: hidden;
  width: 100%;
}
img {
  max-width: 100%;
  height: auto;
}

.no-records {
  text-align: center !important;
}
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #bdbdbd;
  outline: 1px solid #fff;
}

html {
  font-size: 100%; //1rem=16px
  @media only screen and (min-width: 1536px) {
    font-size: 100%; //1rem=18px
  }
  @media only screen and (min-width: 1920px) {
    font-size: 118.75%; //1rem=19px
  }
}

.cus-btn-arrow {
  color: #ffffff;
  background: url("../../assets/images/arrow_layout.svg") no-repeat right;
  background-color: rgb(0, 145, 223);
  .label {
    margin-left: -25px;
  }
}

.activities-criteria {
  .error-border > fieldset {
    border-color: red;
  }

  .error-border-date > .MuiInputBase-root > .MuiOutlinedInput-notchedOutline {
    border-color: red;
  }

  .error-border-select > .css-1g0ym13-control {
    border-color: red;
  }
}
