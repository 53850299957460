// .doc-print {
//   width: 21cm;
//   min-height: 29.7cm;
//   padding: 2cm;
//   background-color: white;
//   margin: auto;
//   overflow: visible;
//   position: relative;
// }


// .page-break {
//   page-break-after: always;
// }

// @media print {
//   @page{
//     size: A4;
//     margin: 0;
//   }

//   body{
//     margin: 0;
//   }
//   .doc-print{
//     width: 100%;
//     height: auto;
//     overflow: hidden;
//     padding: 1.25cm;
    
//   }

//   .page-break{
//     display: block;
//     page-break-before: always;
//   }
// }

textarea, textarea:focus
{
  outline-width: 0;
  outline: none;
}
::-webkit-resizer {
  border: 0px solid black;
  background-image: url("../../../../assets/images/resize.png");
  // box-shadow: 0 0 5px 5px blue;
  background-repeat: no-repeat, repeat;
  outline: 0px solid yellow;
  padding:10px
}

textarea::-webkit-scrollbar {
  width: 22px;
  }

textarea::-webkit-filter{
   blur:0.000001px;
}




.doc-print{
  padding: 20px;
  width: 100%;
}

@media print{
  body{
    print-color-adjust: exact !important;
  }
  .doc-print{
    padding: 0;
  }
}